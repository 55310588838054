.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* 
  background-color: #2d3136; 
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  */
  justify-content: center;
  font-size: calc(6px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.background-container {
  background-image: url('natureBackground.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.container {
  display: flex;
  justify-content: center;
  height: 86vh;
  width: 100%;
  transform: translate(0%, 8%);
}

.translucent-frame {
  background-color: rgba(255, 255, 255, 0.6); /* Translucent white background */
  border-radius: 20px; /* Rounded edges */
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.8); /* Box shadow for depth effect */
  width: 80%;
  display: flex;
  flex-direction: column;
  align-content: center;
  
}

.title-content {
  /* position: relative; */
  background-color: transparent;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: rgb(0, 0, 0);
  

}

.content {
  width: 90%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6); /* Translucent white background */
  border-radius: 20px; /* Rounded edges */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8); /* Box shadow for depth effect */
  /* margin: 0 auto; */
  margin-top: 0;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 5%;
  
  font-size: calc(4px + 2vmin);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.circle-image {
  width: 100px;
  height: auto;
  border-radius: 100%; /* Clip the image to a circle shape */
  overflow: hidden; /* Hide any overflow beyond the circle */
}

.circle-image img {
  width: 100%; /* Make the image fill the container */
  height: 100%;
  object-fit: cover; /* Maintain the aspect ratio and cover the container */
}

.graph-content {
  width: auto;
  padding: 10px;
  background-color: white;
  border-radius: 10px; /* Rounded edges */
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.8); /* Box shadow for depth effect */
}

.sign-out-button {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #ffffff;
  color: #333333;
  border: none;
  border-radius: 4px;
  /* padding: 8px 16px; */
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.sign-out-button:hover {
  background-color: #eaeaea;
}

.sign-out-button:focus {
  outline: none;
}

.settings-box {
  width: 90%;
  background-color: rgba(255, 255, 255, 0.6); /* Translucent white background */
  border-radius: 20px; /* Rounded edges */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8); /* Box shadow for depth effect */
  margin: 0 auto;
  font-size: calc(4px + 2vmin);
  padding-left: 3%;
  padding-right: 3%;
}

.side-by-side-div {
  display: flex;
  justify-content: center;
}

.refill-button {
  flex: 1;
  text-align: center;
}

.refill-button-image {
  background-image: url("https://cdn.discordapp.com/attachments/1122592461106196571/1127351102925459486/refillOtter.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100px;
  height: 100px;
  border-radius: 100%; /* Clip the image to a circle shape */
  overflow: hidden; /* Hide any overflow beyond the circle */
}

.view-graph-button {
  padding: 5%;
  text-align: center;
}

.view-graph-button-image {
  background-image: url("https://cdn.discordapp.com/attachments/1122592461106196571/1127351126551953511/graphimage.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100px;
  height: 100px;
  border-radius: 100%; /* Clip the image to a circle shape */
  overflow: hidden; /* Hide any overflow beyond the circle */
}

.under-button-text {
  text-align: center;
  font-size: calc(1px + 2vmin);
}


.topButtons {
  display: inline-block;
  padding: 10px 20px;
  margin: 5px;
  text-decoration: none;
  font-weight: bold;
  border-radius: 5px;
  background-color: #007BFF;
  color: white;
  transition: background-color 0.3s;
}

.topButtons:hover {
  background-color: #0056b3;
}


.banner {
  height: 300px;
  background-image: url('udra_background_2.png'); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  margin-bottom: 40px;
}

.links {
  display: flex;
  justify-content: space-evenly;
  padding: 0 50px;
}

.links h2 {
  border-bottom: 2px solid #333;
  padding-bottom: 10px;
}

.links ul {
  list-style-type: none;
  padding: 0;
}

.links li {
  margin-bottom: 10px;
}

.links a {
  text-decoration: none;
  color: #333;
}

.links a:hover {
  text-decoration: underline;
}

header h1 {
  text-align: center; /* Center the text horizontally */
  font-size: 3em;     /* Adjust the font size. 3em means 3 times the size of the current font */
  margin-top: 20px;   /* Add some top margin for spacing */
  margin-bottom: 20px; /* Add some bottom margin for spacing */
}
